import axios from "../services/axios";

export const create_ticket = async (event_id, ticket, sort) => {
	const new_ticket = await axios
		.post(`/api/events/${event_id}/tickets`, {
			title: ticket.title,
			description: ticket.description,
			price: parseInt(ticket.price),
			amount: parseInt(ticket.amount),
			sell_start_at: ticket.sell_start_at === null ? null : new Date(ticket.sell_start_at),
			sell_end_at: ticket.sell_end_at === null ? null : new Date(ticket.sell_end_at),
			min_per_order: parseInt(ticket.min_per_order),
			max_per_order: parseInt(ticket.max_per_order),
			visibility: ticket.visibility,
			restricted: "",
			sort: sort,
			participation_form: ticket.participation_form,
			ticket_type: ticket.ticket_type,
		})
		.then((e) => e.data)
		.catch((e) => {
			console.log(e);
			return null;
		});

	return new_ticket;
};

export const update_ticket = async (event_id, ticket, sort) => {
	const new_ticket = await axios
		.put(`/api/events/${event_id}/tickets/${ticket.id}`, {
			title: ticket.title,
			description: ticket.description,
			price: parseInt(ticket.price),
			amount: parseInt(ticket.amount),
			sell_start_at: ticket.sell_start_at === null ? null : new Date(ticket.sell_start_at),
			sell_end_at: ticket.sell_end_at === null ? null : new Date(ticket.sell_end_at),
			min_per_order: parseInt(ticket.min_per_order),
			max_per_order: parseInt(ticket.max_per_order),
			visibility: ticket.visibility,
			restricted: "",
			sort: sort,
			participation_form: ticket.participation_form,
			ticket_type: ticket.ticket_type,
		})
		.then((e) => e.data)
		.catch((e) => {
			console.log(e);
			return null;
		});

	return new_ticket;
};
