import React, { useState } from "react";
import "./style.scss";
import { Button, Fade, Popper } from "@mui/material";
import * as XLSX from "xlsx";
import CheckIcon from "@mui/icons-material/Check";
import TuneIcon from "@mui/icons-material/Tune";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const TableHeader = ({ title, columns, all_columns, full_data, selection, clearSelection, setColumns }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "column-popover" : undefined;

	const export_xls = () => {
		const data = [];

		const base_ids = selection.length ? selection : full_data.map((e, id) => id);

		for (let index = 0; index < base_ids.length; index++) {
			const id = base_ids[index];
			const item = full_data[id];
			const item_filtered = {};

			for (let index = 0; index < columns.length; index++) {
				const column = columns[index];
				item_filtered[column["headerName"]] = item[column["field"]];
				if (columns[index]["exportCell"]) {
					item_filtered[column["headerName"]] = columns[index]["exportCell"](item[column["field"]]);
				}
			}
			data.push(item_filtered);
		}

		const columnWidths = data[0]
			? Object.keys(data[0]).map((key) => {
					// Adjust the width of each column based on the maximum length of the values in the column
					const maxLength = data.reduce(
						(max, row) => Math.max(max, row[key] ? row[key].toString().length : 0),
						key.length
					);
					return { wch: maxLength + 5 }; // Add some padding
			  })
			: [];

		const worksheet = XLSX.utils.json_to_sheet(data);
		worksheet["!cols"] = columnWidths;
		const workbook = XLSX.utils.book_new();
		const now = new Date().toLocaleDateString("fr-FR", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		});
		XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");
		XLSX.writeFile(workbook, `${title.toLowerCase()}_export_${now}.xlsx`);
		clearSelection();
	};

	const toggle_column = (field) => {
		const new_columns = [...columns];
		const existing = columns.filter((column) => column.field === field);

		if (existing.length) {
			new_columns.splice(new_columns.indexOf(existing[0]), 1);
		} else {
			new_columns.push(all_columns.filter((e) => e.field === field)[0]);
		}

		setColumns(new_columns);
	};

	return (
		<div className="passpass-table-header">
			<Popper id={id} open={open} anchorEl={anchorEl} onClose={handleClose} placement="bottom" transition>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps}>
						<div className="passpass-table-column-selector">
							<div className="column-selector-header">
								<span>Activer des colonnes</span>
							</div>
							<div className="columns-container">
								{all_columns.map((column, index) => (
									<div
										onClick={() => toggle_column(column.field)}
										className="column-item"
										key={index}
									>
										{columns.filter((c) => c.field === column.field).length !== 0 ? (
											<CheckIcon fontSize="inherit" />
										) : (
											<CheckIcon fontSize="inherit" className="hidden" />
										)}
										<span>{column.headerName}</span>
									</div>
								))}
							</div>
						</div>
					</Fade>
				)}
			</Popper>
			<h1>{title}</h1>
			<div className="table-header-filters-container">
				<div onClick={export_xls} className="export-btn header-btn">
					<UploadFileIcon />
					<span>{selection.length ? "Export selection" : "Export"}</span>
				</div>
				<div onClick={anchorEl ? handleClose : handleClick} className="view-btn header-btn">
					<TuneIcon />
					<span>Colonnes</span>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
