import React, { useContext, useEffect, useRef, useState } from "react";
import DefaultView from "../../../components/events/default_view/DefaultView";
import "./style.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../services/axios";
import CurrentEventContext from "../../../contexts/CurrentEventContext";
import CellBoolean from "components/table_components/CellBoolean";
import CellDate from "components/table_components/CellDate";
import PassPassTable from "components/table/table/PassPassTable";
import { format_date, format_price, format_time } from "helpers/format";
import RenderUser from "components/events/main/render_user/RenderUser";

const columns = [
	{
		width: 120,
		headerName: "Entry id",
		field: "str_id",
	},
	{
		width: 100,
		headerName: "Is enter",
		field: "is_in",
		renderCell: (e) => <CellBoolean value={e.value} />,
	},
	{
		width: 200,
		headerName: "Validation date",
		field: "validated_at",
		renderCell: (e) => <CellDate date={e.value} />,
	},
];

const EventTicket = () => {
	const { event_id, ticket_id } = useParams();
	const [ticket, setTicket] = useState(null);
	const navigate = useNavigate();
	const { event } = useContext(CurrentEventContext);

	const load_ticket = async () => {
		const ticket_data = await axios
			.get(`/api/events/${event_id}/participant-ticket/${ticket_id}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ticket_data) {
			ticket_data.validations.forEach((validation) => {
				const validated_at = new Date(validation.validated_at);
				validation.str_id = validation.id.toString().padStart(8, "0");
				validation.v_at = validated_at;
			});
			ticket_data.validations = ticket_data.validations.sort().sort((a, b) => a.id - b.id);
			setTicket(ticket_data);
		}
	};

	useEffect(() => {
		if (event?.id === parseInt(event_id)) {
			load_ticket();
		}
	}, [event]);

	if (!ticket) {
		return <DefaultView></DefaultView>;
	}

	return (
		<DefaultView>
			<div id="event-ticket" className="passpass-single-pages">
				<div className="single-pages-block">
					<span className="title">Ticket {ticket.ticket.id.toString().padStart(8, "0")}</span>
					<span className="divider"></span>
					<div className="info-container">
						<div className="info-item">
							<span className="info-name">Date d'achat</span>
							<div className="info-content">
								{format_date(ticket.ticket.created_at, "full_short")} à{" "}
								{format_time(ticket.ticket.created_at)}
							</div>
						</div>
						<div className="info-item">
							<span className="info-name">Dèrniere validation</span>
							{ticket.ticket.last_validation ? (
								<>
									<div className="info-content">
										{format_date(ticket.ticket.last_validation, "full_short")} à{" "}
										{format_time(ticket.ticket.last_validation)}
									</div>
								</>
							) : (
								<div className="info-content">Aucune validation</div>
							)}
						</div>
						<div className="info-item">
							<span className="info-name">Acheteur</span>
							<div className="info-content">
								<RenderUser user={ticket.buyer} />
							</div>
						</div>
						<div className="info-item">
							<span className="info-name">Profile</span>
							<div className="info-content">
								<RenderUser user={ticket.profile} />
							</div>
						</div>
					</div>
				</div>
				<div className="single-pages-block">
					<span className="divider"></span>
					<div className="info-container">
						<div className="info-item">
							<span className="info-name">Commande</span>
							<div className="info-content">
								<Link
									to={`/events/${event_id}/orders/${ticket.ticket.payment}`}
									className="render-user"
								>
									{ticket.ticket.payment}
								</Link>
							</div>
						</div>
						<div className="info-item">
							<span className="info-name">Prix d'achat</span>
							<div className="info-content">{format_price(ticket.ticket.price)}</div>
						</div>
					</div>
				</div>
				<div className="single-pages-block">
					<span className="title">Validations du ticket</span>
					<span className="divider"></span>
					{ticket.validations.length !== 0 ? (
						<PassPassTable
							columns={columns.map((column, index) => {
								column.order = index;
								return column;
							})}
							pageSizeOptions={[10, 20, 50, 100]}
							title="Validations du ticket"
							data={ticket.validations.reverse()}
							light={true}
						/>
					) : (
						<span>Aucune validation</span>
					)}
				</div>
			</div>
		</DefaultView>
	);
};

export default EventTicket;
