import DefaultView from "components/events/default_view/DefaultView";
import AuthContext from "contexts/AuthContext";
import CurrentEventContext from "contexts/CurrentEventContext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "styles/dashboard.scss";
import axios from "../../../services/axios";
import PassPassTable from "components/table/table/PassPassTable";
import RenderUser from "components/events/main/render_user/RenderUser";
import CellLink from "components/table_components/CellLink";
import { format_price } from "helpers/format";
import CellDate from "components/table_components/CellDate";
import CellStatus from "components/table_components/CellStatus";

const columns = [
	{
		width: 200,
		headerName: "Id",
		field: "id",
		renderCell: (e) => (
			<CellLink
				label={`TIC-${e.value.toString().padStart(8, "0")}`}
				v={e}
				to={`/events/${e.row.event}/tickets/${e.row.id}`}
			/>
		),
	},
	{
		width: 200,
		headerName: "Ticket",
		field: "title",
	},
	{
		width: 200,
		headerName: "Nom sur le ticket",
		field: "title",
		renderCell: (e) => (
			<span>
				{e.row.first_name} {e.row.last_name}
			</span>
		),
	},
	{
		width: 100,
		headerName: "Prix d'achat",
		field: "price",
		renderCell: (e) => <span>{format_price(e.value)}</span>,
	},
	{
		width: 100,
		headerName: "Type de ticket",
		field: "type",
		renderCell: (e) => (
			<CellStatus
				status={e.value}
				label_list={{ ticket: "Entrée", addon: "Suppléments" }}
				color_list={{ ticket: "#A9CCE3", addon: "#D4EFDF" }}
			/>
		),
	},
	{
		width: 200,
		headerName: "Last Validation",
		field: "last_validation_string",
		renderCell: (e) => <CellDate date={e.value} />,
	},
];

const EventOrder = () => {
	const { event_id, order_id } = useParams();
	const { event } = useContext(CurrentEventContext);
	const { user } = useContext(AuthContext);
	const [order, setOrder] = useState(null);

	const load_order = async () => {
		const order_data = await axios
			.get(`/api/dashboard/${user.account.id}/events/${event_id}/payments/${order_id}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (order_data) {
			order_data.user_tickets.forEach((ticket) => {
				const existing = event.tickets.find((event_ticket) => event_ticket.id === ticket.ticket);
				ticket.title = existing.title;
				ticket.type = existing.ticket_type;
				ticket.last_validation_string = ticket.last_validation ? ticket.last_validation : "Not validated";
			});
			setOrder(order_data);
		}
	};

	useEffect(() => {
		if (event?.id === parseInt(event_id)) {
			load_order();
		}
	}, [event]);

	if (!order) {
		return <DefaultView></DefaultView>;
	}

	return (
		<DefaultView>
			<div id="event-participant" className="passpass-single-pages">
				<div className="single-pages-block">
					<span className="title">
						Commande <span style={{ fontSize: "12px" }}>{order.id}</span>
					</span>
					<span className="divider"></span>
					<div className="info-container">
						<div className="info-item">
							<span className="info-name">Valeur commande</span>
							<div className="info-content">{format_price(order.amount)}</div>
						</div>
						<div className="info-item">
							<span className="info-name">Nombre d'entrées</span>
							<div className="info-content">{order.user_tickets.length}</div>
						</div>
						<div className="info-item">
							<span className="info-name">Nombre de suppléments</span>
							<div className="info-content">{order.user_tickets.length}</div>
						</div>
					</div>
				</div>

				<div className="single-pages-block">
					<span className="title">
						Participant <span style={{ fontSize: "12px" }}>{order.profile}</span>
					</span>
					<span className="divider"></span>
					<div className="info-container">
						<div className="info-item">
							<span className="info-name">Profile</span>
							<div className="info-content">
								<RenderUser user={order.profile_data} />
							</div>
						</div>
						{order.profile_data.email.includes("@") ? (
							<div className="info-item">
								<span className="info-name">Email du participant</span>
								<div className="info-content">{order.profile_data.email}</div>
							</div>
						) : null}
					</div>
				</div>

				<div className="single-pages-block">
					<span className="title">Tickets de la commande</span>
					<span className="divider"></span>
					{order.user_tickets.length !== 0 ? (
						<PassPassTable
							columns={columns.map((column, index) => {
								column.order = index;
								return column;
							})}
							pageSizeOptions={[5, 20, 50, 100]}
							title="Validations du ticket"
							data={order.user_tickets}
							light={true}
						/>
					) : (
						<span>Aucun ticket</span>
					)}
				</div>
			</div>
		</DefaultView>
	);
};

export default EventOrder;
