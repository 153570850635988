import React, { useContext, useEffect, useState } from "react";
import DefaultView from "../../../components/events/default_view/DefaultView";
import "./style.scss";
import { ReactComponent as TimeIcon } from "../../../assets/static/time.svg";
import AuthContext from "contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import CurrentEventContext from "contexts/CurrentEventContext";
import { CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, XAxis, YAxis, Tooltip } from "recharts";
import { format_date, format_price, format_time } from "helpers/format";
import { App, Popover } from "antd";
import { simpleHash } from "helpers/main";
import { InfoCircleFilled } from "@ant-design/icons";

const COLORS = ["#9756E2", "#409EFF", "#FF8718", "#FFCF25", "#83FDC0"];

const generate_dashboard_data = (event) => {
	if (!event) return { max_tickets: 0, ticket_sold_data: [], ticket_sold_chart: [] };

	let sales = 0;
	let net_sales = 0;
	let reserved = 0;
	let transfered = 0;
	const ticket_ids = [];
	const availible_tickets = event.tickets.filter((ticket) => ticket.ticket_type === "ticket");

	for (let index = 0; index < event.payments.length; index++) {
		const payment = event.payments[index];
		if (payment.paid) {
			sales += payment.amount;
			net_sales += payment.amount - payment.fees;
			reserved += payment.reserved_amount;
			transfered += payment.transfered_amount;
		}
	}

	for (let index = 0; index < availible_tickets.length; index++) {
		const ticket = availible_tickets[index];
		ticket_ids.push(ticket.id);
	}

	const availible_participants = event.participants.filter((participant) => ticket_ids.includes(participant.ticket));

	const ticket_sold_data = [];
	const max_tickets = availible_tickets.map((e) => e.amount).reduce((partialSum, a) => partialSum + a, 0);

	for (let index = 0; index < availible_tickets.length; index++) {
		const ticket = availible_tickets[index];
		ticket_sold_data.push({
			name: ticket.title,
			value: availible_participants.filter((e) => e.ticket === ticket.id).length,
			color: COLORS[index % COLORS.length],
		});
	}

	ticket_sold_data.push({
		name: "Non vendu",
		value: max_tickets - availible_participants.length,
		color: "#F2F3F4",
	});

	const first_sold = [...availible_participants].sort((a, b) => a.id - b.id);
	const ticket_sold_chart = [];

	if (first_sold.length) {
		let first_date = new Date(first_sold[0].created_date);
		let end = new Date(event.end_at);
		first_date.setDate(first_date.getDate() - 3);
		const now = new Date();
		now.setDate(now.getDate() + 1);

		while (first_date < now && first_date < end) {
			const to_add = {
				name: format_date(first_date, "full_short_no_date"),
			};

			for (let index = 0; index < availible_tickets.length; index++) {
				const ticket = availible_tickets[index];
				to_add[ticket.title] = 0;
			}
			const date_string = first_date.toJSON().substring(0, 10);

			availible_participants
				.filter((e) => e.created_date === date_string)
				.forEach((ticket) => {
					to_add[ticket.ticket_title] += 1;
				});

			ticket_sold_chart.push(to_add);
			first_date.setDate(first_date.getDate() + 1);
		}
	}
	return {
		max_tickets: max_tickets,
		ticket_sold_data: ticket_sold_data,
		ticket_sold_chart: ticket_sold_chart,
		tickets_sold: availible_participants.length,
		sales: sales,
		net_sales: net_sales,
		reserved: reserved,
		transfered: transfered,
	};
};

const EventDashboard = () => {
	const { user } = useContext(AuthContext);
	const { event, load_entries } = useContext(CurrentEventContext);
	const [dimensions, setDimensions] = useState({
		height: typeof window !== "undefined" && window.innerHeight,
		width: typeof window !== "undefined" && window.innerWidth,
	});
	const { notification } = App.useApp();

	useEffect(() => {
		const handleResize = () => {
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const dashboard_data = !event ? {} : generate_dashboard_data(event);

	return (
		<DefaultView>
			<div className="event-dashboard-content">
				<div className="event-dashboard-item thin intro-content small">
					<span className="title">Bonjour {user.first_name},</span>
					<p>Actions rapides pour l’événement</p>
					<div className="buttons-container">
						<Link className="button" to={`/update-event/${event?.id}`}>
							Modifier
						</Link>

						<div
							className="button"
							onClick={() => {
								let url = `${process.env.REACT_APP_SITE_URL}/events/${event?.id}/${encodeURIComponent(
									event?.title.replaceAll(" ", "-")
								)}`;

								if (event.visibility === "private") {
									url = `${process.env.REACT_APP_SITE_URL}/events/${event?.id}/${encodeURIComponent(
										event?.title.replaceAll(" ", "-")
									)}?t=${simpleHash(event.id.toString())}`;
								}

								navigator.clipboard?.writeText(url);
								if (navigator.clipboard) {
									notification.success({
										message: "Lien de l'événement copié dans le presse papier",
										placement: "bottomLeft",
									});
								} else {
									notification.error({
										message: "Impossible de copier le lien de l'événement",
										placement: "bottomLeft",
									});
								}
							}}
						>
							Partager
						</div>
						<Link
							className="button"
							target="_blank"
							to={`${process.env.REACT_APP_SITE_URL}/events/${event?.id}/${event?.title.replaceAll(
								" ",
								"-"
							)}/preview`}
						>
							Prévisualiser
						</Link>
					</div>
				</div>
				<div className="event-dashboard-item large">
					<span className="title">Suivi des réservations</span>
					<span className="divider"></span>
					<div className="graph-container line-container">
						<LineChart
							width={(dimensions.width / 6) * 4 - 140}
							height={250}
							data={dashboard_data.ticket_sold_chart}
							margin={{ top: 0, right: 5, left: -40, bottom: 0 }}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Tooltip />
							<Legend />
							{event?.tickets
								.filter((ticket) => ticket.ticket_type === "ticket")
								.map((elem, id) => (
									<Line
										type="monotone"
										key={id}
										dataKey={elem.title}
										stroke={COLORS[id % COLORS.length]}
										strokeWidth={3}
										dot={{ strokeWidth: 2 }}
									/>
								))}
						</LineChart>
					</div>
				</div>
				<div className="event-dashboard-item thin tall">
					<div className="ticket-sold">
						<div className="flex flex-col">
							<span className="title secondary">Inscriptions</span>
							<span className="sold-count">
								<span className="big">{dashboard_data?.tickets_sold}</span>
							</span>
						</div>
						<div className="total-earn">
							<span className="flex flex-row items-center gap-1">
								Ventes nettes{" "}
								<Popover
									arrow={false}
									content={
										<div className="flex flex-col">
											<span>Ventes brutes</span>
											<span className="text-[#65B5FF]">
												{format_price(dashboard_data?.sales)}
											</span>
											<span>Frais</span>
											<span className="text-[#87CC8E]">
												{format_price(dashboard_data?.sales - dashboard_data?.net_sales)}
											</span>
										</div>
									}
								>
									<InfoCircleFilled style={{ width: "10px" }} />
								</Popover>
							</span>
							<span className="text-2xl leading-7 font-semibold">
								{format_price(dashboard_data?.net_sales)}
							</span>
							<div className="flex flex-row items-center justify-between bg-[#FFECDD] p-2 rounded-md mt-2 w-full">
								<div className="flex flex-row items-center gap-2">
									<TimeIcon width={18} height={18} />
									<span>{format_price(dashboard_data?.reserved)}</span>
								</div>
								<Popover
									arrow={false}
									placement="bottom"
									content={
										<div className="flex flex-col max-w-[300px]">
											<span className="font-semibold">Montant en réserve</span>
											<p>
												Une partie des fonds est retenu temporairement par Stripe pour couvrir
												d’éventuels remboursements, litiges ou fraudes.
											</p>
											<p className="mt-2">
												Ce montant sera progressivement versé sur votre compte bancaire.
											</p>
											<p className="mt-2">
												Montant déjà versé:{" "}
												<span className="font-semibold">
													{format_price(dashboard_data.net_sales - dashboard_data.reserved)}
												</span>
											</p>
										</div>
									}
								>
									<InfoCircleFilled />
								</Popover>
							</div>
						</div>
					</div>
					<span className="divider"></span>
					<div className="pie-container graph-container">
						<PieChart width={200} height={205} margin={{ top: 25, right: 0, left: 0, bottom: 10 }}>
							<Pie
								data={dashboard_data.ticket_sold_data}
								cx={90}
								cy={90}
								innerRadius={55}
								outerRadius={90}
								fill="#8884d8"
								paddingAngle={3}
								dataKey="value"
							>
								{dashboard_data.ticket_sold_data &&
									dashboard_data?.ticket_sold_data.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={entry.color} />
									))}
							</Pie>
							<Tooltip />
						</PieChart>
						<div className="legend">
							{dashboard_data?.ticket_sold_data &&
								dashboard_data?.ticket_sold_data.map((entry, id) => (
									<span key={id} className="legend-item">
										<span className="color" style={{ backgroundColor: entry.color }}></span>
										<span className="label">{entry.name}</span>
									</span>
								))}
						</div>
					</div>
				</div>
				<div className="event-dashboard-item large">
					<span className="title">Dernières réservations</span>
					<span className="divider"></span>
					<div className="last-reservations">
						<table>
							<tbody>
								{event &&
									[...event?.participants]
										.sort((a, b) => b.id - a.id)
										.map((participant, id) => (
											<tr key={id}>
												<td>{participant.ticket_title}</td>
												<td>{format_price(participant.price)}</td>
												<td>{participant.first_name}</td>
												<td>{participant.last_name}</td>
												<td>
													{format_date(participant.created_at, "full_short")}{" "}
													{format_time(participant.created_at)}
												</td>
											</tr>
										))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default EventDashboard;
