import React, { useEffect } from "react";
import "./style.scss";
import { Button } from "antd";

const Modal = ({
	open,
	onClose,
	title,
	save,
	save_message,
	cancel,
	is_valid,
	children,
	maxWidth = "800px",
	loading = false,
}) => {
	let can_close = true;

	const check_escape = (e) => {
		if (e.key === "Escape") {
			onClose();
		}
	};

	useEffect(() => {
		if (open) {
			document.addEventListener("keydown", check_escape);
			document.body.classList.add("no-scroll");
		}

		return () => {
			document.removeEventListener("keydown", check_escape);
			document.body.classList.remove("no-scroll");
		};
	}, [open]);

	if (!open) {
		return null;
	}

	return (
		<div
			className="passpass-modal"
			onClick={() => {
				if (can_close) {
					onClose();
				}
				can_close = true;
			}}
		>
			<div className="modal-center" style={{ maxWidth: maxWidth }} onClick={() => (can_close = false)}>
				<div className="modal-header">
					<span className="title">{title}</span>
				</div>
				<div className="modal-content">{children}</div>
				<div className="modal-footer">
					<Button disabled={loading} onClick={cancel}>
						Annuler
					</Button>
					<Button loading={loading} onClick={save} disabled={!is_valid} className="main" type="primary">
						{save_message}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Modal;
