import React, { useContext, useState } from "react";
import "./style.scss";
import { LoadingButton } from "@mui/lab";
import EventCreationContext from "../../../contexts/EventCreationContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SummaryAside from "components/events/create_update_event/summary_aside/SummaryAside";
import EditIcon from "@mui/icons-material/Edit";
import AuthContext from "contexts/AuthContext";
import { format_price } from "helpers/format";
import ReactShowMoreText from "react-show-more-text";

const Summary = () => {
	const { loading, publish, validation, step, change_step, event } = useContext(EventCreationContext);
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();

	let is_valid = true;
	for (const [key, value] of Object.entries(validation)) {
		if (value === false) is_valid = false;
	}

	return (
		<>
			<div className="create-update-center">
				<div onClick={() => change_step(step.step - 1)} className="go-back-container">
					<ArrowBackIosIcon />
					<span>Suppléments</span>
				</div>
				<h1>Récapitulatif de l'événement</h1>
				<div className="summary-container">
					<div onClick={() => change_step(0)} className="edit-btn">
						<EditIcon />
					</div>
					<div className="event-value-container">
						<span className="title">Nom de l'événement</span>
						<p>{event.title}</p>
					</div>
					<div className="event-value-container">
						<span className="title">Organisé par</span>
						<p>{user.account.title}</p>
					</div>
				</div>
				<div className="summary-container">
					<div onClick={() => change_step(0)} className="edit-btn">
						<EditIcon />
					</div>
					<div className="event-value-container">
						<span className="title">Texte d'introduction</span>
						<p>{event.small_description}</p>
					</div>
					<div className="event-value-container">
						<span className="title">Description</span>
						<ReactShowMoreText
							more="Voir plus"
							less="Voir moins"
							lines={2}
							className="description-container"
							anchorClass="show-more-less-clickable"
							expanded={false}
						>
							<div
								className="description-content"
								dangerouslySetInnerHTML={{ __html: event.description }}
							/>
						</ReactShowMoreText>
					</div>
				</div>
				<div className="summary-container">
					<div style={{ marginBottom: 0 }} className="event-value-container header">
						<span className="title">Couleur primaire</span>

						<div className="row">
							<div className="color-container">
								<span>{event.primary_color}</span>
								<div className="color-block" style={{ backgroundColor: event.primary_color }}></div>
							</div>
							<div onClick={() => change_step(1)} className="edit-btn fixed">
								<EditIcon />
							</div>
						</div>
					</div>
				</div>
				<div className="summary-container">
					<div className="event-value-container header">
						<span className="title">Tickets</span>

						<div onClick={() => change_step(2)} className="edit-btn fixed">
							<EditIcon />
						</div>
					</div>
					<table className="ticket-wrapper">
						<tbody>
							{event.tickets
								.filter((e) => e.ticket_type === "ticket")
								.map((ticket, index) => (
									<tr key={index}>
										<td>
											<p style={{ maxWidth: "180px" }} className="one-line">
												{ticket.title}
											</p>
										</td>
										<td>
											<p style={{ maxWidth: "220px" }} className="one-line">
												{ticket.description}
											</p>
										</td>
										<td>{format_price(ticket.price)}</td>
										<td>{ticket.amount} unités</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
				<div className="summary-container">
					<div className="event-value-container header">
						<span className="title">Suppléments</span>

						<div onClick={() => change_step(3)} className="edit-btn fixed">
							<EditIcon />
						</div>
					</div>
					<table className="ticket-wrapper">
						<tbody>
							{event.tickets
								.filter((e) => e.ticket_type === "addon")
								.map((ticket, index) => (
									<tr key={index}>
										<td>
											<p style={{ maxWidth: "200px" }} className="one-line">
												{ticket.title}
											</p>
										</td>
										<td>
											<p style={{ maxWidth: "200px" }} className="one-line">
												{ticket.description}
											</p>
										</td>
										<td>{format_price(ticket.price)}</td>
										<td>{ticket.amount} unités</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>

			<SummaryAside />
		</>
	);
};

export default Summary;
