import React from "react";
import { createContext, useState } from "react";
import axios from "../services/axios";

const EventsContext = createContext();

export const EventsProvider = (props) => {
	const [events, setEvents] = useState([]);

	const load_events = async (organizer_id) => {
		const response = await axios
			.get(`api/dashboard/${organizer_id}/events`)
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			response.forEach((event) => {
				event.start_at_time = new Date(event.start_at).getTime();
			});
			setEvents(response.sort((a, b) => b.id - a.id));
		}
	};

	const update_event_status = async (event_id, status) => {
		const response = await axios
			.put(`api/events/${event_id}/status/${status}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			setEvents(
				events.map((event) => {
					if (event.id === event_id) {
						event.status = status;
					}
					return event;
				})
			);
		}
	};

	return (
		<EventsContext.Provider
			value={{
				events,
				load_events,
				update_event_status,
			}}
		>
			{props.children}
		</EventsContext.Provider>
	);
};

export default EventsContext;
