import React, { useContext, useEffect, useRef, useState } from "react";
import DefaultView from "../../../components/events/default_view/DefaultView";
import "./style.scss";
import "../event_ticket/style.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../services/axios";
import { DataGrid } from "@mui/x-data-grid";
import CurrentEventContext from "../../../contexts/CurrentEventContext";
import PassPassTable from "components/table/table/PassPassTable";
import RenderUser from "components/events/main/render_user/RenderUser";
import { format_price } from "helpers/format";
import CellDate from "components/table_components/CellDate";
import CellLink from "components/table_components/CellLink";

const columns = [
	{
		width: 200,
		headerName: "Id",
		field: "id",
		renderCell: (e) => (
			<CellLink
				label={`TIC-${e.value.toString().padStart(8, "0")}`}
				v={e}
				to={`/events/${e.row.event}/tickets/${e.row.id}`}
			/>
		),
	},
	{
		width: 200,
		headerName: "Ticket",
		field: "title",
	},
	{
		width: 200,
		headerName: "Nom sur le ticket",
		field: "title",
		renderCell: (e) => (
			<span>
				{e.row.first_name} {e.row.last_name}
			</span>
		),
	},
	{
		width: 100,
		headerName: "Prix d'achat",
		field: "price",
		renderCell: (e) => <span>{format_price(e.value)}</span>,
	},
	{
		width: 200,
		headerName: "Last Validation",
		field: "last_validation_string",
		renderCell: (e) => <CellDate date={e.value} />,
	},
];

const EventParticipant = () => {
	const { event_id, participant_id } = useParams();
	const [participant, setParticipant] = useState(null);
	const { event } = useContext(CurrentEventContext);

	const load_participant = async () => {
		const participant_data = await axios
			.get(`/api/events/${event_id}/participants/${participant_id}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (participant_data) {
			participant_data.tickets.forEach((ticket) => {
				const existing = event.tickets.find((event_ticket) => event_ticket.id === ticket.ticket);
				ticket.title = existing.title;
				ticket.last_validation_string = ticket.last_validation ? ticket.last_validation : "Not validated";
			});
			participant_data.addons = participant_data.tickets.filter((e) => e.ticket_type === "addon");
			participant_data.tickets = participant_data.tickets.filter((e) => e.ticket_type === "ticket");
			setParticipant(participant_data);
		}
	};

	useEffect(() => {
		if (event?.id === parseInt(event_id)) {
			load_participant();
		}
	}, [event]);

	if (!participant) {
		return <DefaultView></DefaultView>;
	}

	return (
		<DefaultView>
			<div id="event-participant" className="passpass-single-pages">
				<div className="single-pages-block">
					<span className="title">
						Participant <span style={{ fontSize: "12px" }}>{participant.id}</span>
					</span>
					<span className="divider"></span>
					<div className="info-container">
						<div className="info-item">
							<span className="info-name">Nombre de tickets</span>
							<div className="info-content">{participant.tickets.length}</div>
						</div>
						<div className="info-item">
							<span className="info-name">Nombre de suppléments</span>
							<div className="info-content">{participant.addons.length}</div>
						</div>
						<div className="info-item">
							<span className="info-name">Participant</span>
							<div className="info-content">
								<RenderUser user={participant.profile} />
							</div>
						</div>
						{participant.profile.email.includes("@") ? (
							<div className="info-item">
								<span className="info-name">Email du participant</span>
								<div className="info-content">{participant.profile.email}</div>
							</div>
						) : null}
					</div>
				</div>

				<div className="single-pages-block">
					<span className="title">Tickets du participant</span>
					<span className="divider"></span>
					{participant.tickets.length !== 0 ? (
						<PassPassTable
							columns={columns.map((column, index) => {
								column.order = index;
								return column;
							})}
							pageSizeOptions={[5, 20, 50, 100]}
							title="Validations du ticket"
							data={participant.tickets}
							light={true}
						/>
					) : (
						<span>Aucun ticket</span>
					)}
				</div>

				<div className="single-pages-block">
					<span className="title">Supplements du participant</span>
					<span className="divider"></span>
					{participant.addons.length !== 0 ? (
						<PassPassTable
							columns={columns.map((column, index) => {
								column.order = index;
								return column;
							})}
							pageSizeOptions={[5, 20, 50, 100]}
							title="Validations du ticket"
							data={participant.addons}
							light={true}
						/>
					) : (
						<span>Aucun supplements</span>
					)}
				</div>
			</div>
		</DefaultView>
	);
};

export default EventParticipant;
