import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import EventCreationContext from "../../../contexts/EventCreationContext";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CreateUpdateAside from "components/events/create_update_event/create_update_aside/CreateUpdateAside";
import TicketItem from "components/events/create_update_event/tickets/TicketItem";
import TIcketUpdateModal from "components/events/create_update_event/tickets/TIcketUpdateModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TicketWrapper from "components/events/create_update_event/tickets/ticket_wrapper/TicketWrapper";
import { Tabs } from "antd";
import ParticipationForm from "components/events/create_update_event/tickets/participation_form/ParticipationForm";
import { useSearchParams } from "react-router-dom";
import PromoCode from "components/events/create_update_event/tickets/promo_code/PromoCode";

const Tickets = () => {
	const { event, update_event, step, change_step, base_data } = useContext(EventCreationContext);
	const [reload, setReload] = useState(false);
	const formData = useRef({
		tickets: JSON.parse(JSON.stringify(event.tickets.filter((e) => e.ticket_type === "ticket"))),
		addons: JSON.parse(JSON.stringify(event.tickets.filter((e) => e.ticket_type === "addon"))),
	});
	const [updateTicket, setUpdateTicket] = useState({
		item: null,
	});
	const [formDataState, setformDataState] = useState({ ...formData.current });
	const [canSave, setCanSave] = useState(false);
	let [searchParams, setSearchParams] = useSearchParams();

	const save_data = () => {
		update_event("tickets", [...formData.current.tickets, ...formData.current.addons]);
	};

	const check_if_can_save = () => {
		let change = false;
		if (
			JSON.stringify(base_data.current.tickets.filter((e) => e.ticket_type === "ticket")) !=
			JSON.stringify(formData.current.tickets)
		) {
			change = true;
		} else if (
			JSON.stringify(base_data.current.tickets.filter((e) => e.ticket_type === "addon")) !=
			JSON.stringify(formData.current.addons)
		) {
			change = true;
		}
		setCanSave(change);
	};

	useEffect(() => {
		return () => {
			save_data();
		};
	}, []);

	const tab_items = [
		{
			key: "1",
			label: "Entrées",
			children: (
				<TicketWrapper
					update={setUpdateTicket}
					formData={formData}
					all_tickets={formData.current.tickets}
					event={event}
					check_if_can_save={check_if_can_save}
				/>
			),
		},
		{
			key: "2",
			label: "Suppléments",
			children: (
				<TicketWrapper
					update={setUpdateTicket}
					formData={formData}
					all_tickets={formData.current.addons}
					event={event}
					check_if_can_save={check_if_can_save}
					is_addon={true}
				/>
			),
		},
		{
			key: "3",
			label: "Formulaire",
			children: <ParticipationForm tickets={[...formData.current.tickets, ...formData.current.addons]} />,
		},
		{
			key: "4",
			label: "Codes promotionnels",
			children: <PromoCode tickets={[...formData.current.tickets, ...formData.current.addons]} />,
		},
	];

	return (
		<>
			<div className="create-update-center">
				<TIcketUpdateModal
					event={event}
					open={updateTicket.item !== null}
					handleClose={() => {
						if (
							formData.current.tickets[updateTicket.item] &&
							!formData.current.tickets[updateTicket.item]?.title
						) {
							formData.current.tickets.splice(updateTicket.item);
							setformDataState({
								...formDataState,
								tickets: formData.current.tickets,
							});
						}
						setUpdateTicket({ item: null });
					}}
					ticket={formDataState.tickets[updateTicket.item]}
					is_new={updateTicket.new}
					saveTicket={(ticket) => {
						formData.current.tickets[updateTicket.item] = ticket;
						setformDataState({
							...formDataState,
							tickets: formData.current.tickets,
						});
						setUpdateTicket({ item: null });
					}}
				/>
				<div onClick={() => change_step(step.step - 1)} className="go-back-container">
					<ArrowBackIosIcon />
					<span>Personnalisation</span>
				</div>
				<h1>Tickets</h1>
				<Tabs
					onChange={(e) => setSearchParams({ step: step.step, "ticket-tab": e })}
					defaultActiveKey={
						"1234".includes(searchParams.get("ticket-tab")) ? searchParams.get("ticket-tab") : "1"
					}
					items={tab_items}
				/>
			</div>
			<CreateUpdateAside
				tips={[
					{
						title: "1 ticket = 1 entrée",
						content:
							"Un ticket est considéré comme une entrée, cela permet donc un certain accès. Si vous voulez vendre un ticket sans accès, vous cherchez peut-être à créer un supplément.",
					},
					{
						title: "Ordre respecté",
						content:
							"L'ordre dans lequel vous placez les tickets sera reflété sur le site web pour vos clients.",
					},
				]}
				can_save={canSave}
				clean={() => setCanSave(false)}
				save={save_data}
			/>
		</>
	);
};
export default Tickets;
