import React, { useContext, useEffect, useState } from "react";
import DefaultView from "../../../components/events/default_view/DefaultView";
import "./style.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import CurrentEventContext from "../../../contexts/CurrentEventContext";
import EmptyView from "components/main/empty_view/EmptyView";
import CellLink from "components/table_components/CellLink";
import CellDate from "components/table_components/CellDate";
import PassPassTable from "components/table/table/PassPassTable";
import { format_date, format_price, format_time } from "helpers/format";
import * as XLSX from "xlsx";

const columns = [
	{
		width: 140,
		headerName: "Ticket ID",
		field: "str_id",
		renderCell: (e) => <CellLink label={e.value} v={e} to={`/events/${e.row.event}/tickets/${e.row.id}`} />,
		hidden: true,
	},
	{
		width: 140,
		headerName: "Participant ID",
		field: "profile",
		renderCell: (e) => <CellLink label={e.value} to={`/events/${e.row.event}/participants/${e.row.profile}`} />,
		hidden: true,
	},
	{
		width: 100,
		headerName: "Prénom",
		field: "first_name",
		renderCell: (e) => <CellLink label={e.value} to={`/events/${e.row.event}/participants/${e.row.profile}`} />,
	},
	{
		width: 100,
		headerName: "Nom",
		field: "last_name",
		renderCell: (e) => <CellLink label={e.value} to={`/events/${e.row.event}/participants/${e.row.profile}`} />,
	},
	{
		width: 100,
		headerName: "Email",
		field: "email",
	},
	{
		width: 100,
		headerName: "Nom du ticket",
		field: "ticket_title",
	},
	{
		width: 100,
		headerName: "Code promo",
		field: "promo_code_key",
		hidden: true,
	},
	{
		width: 100,
		headerName: "Prix d'achat",
		field: "price",
		renderCell: (e) => <span>{format_price(e.value)}</span>,
		exportCell: (e) => parseFloat((e / 100).toFixed(2)),
	},

	{
		width: 200,
		headerName: "Dernière validation",
		field: "last_validation",
		renderCell: (e) => <CellDate date={e.value} />,
		exportCell: (e) => {
			if (!e) return "";
			const real_date = new Date(e);
			return `${format_date(real_date, "full_short")} | ${format_time(real_date)}`;
		},
		hidden: true,
	},
	{
		width: 200,
		headerName: "Date d'achat",
		field: "created_at",
		renderCell: (e) => <CellDate date={e.value} />,
		exportCell: (e) => {
			if (!e) return "";
			const real_date = new Date(e);
			return `${format_date(real_date, "full_short")} | ${format_time(real_date)}`;
		},
		hidden: true,
	},
	{
		width: 200,
		headerName: "Créneau horaire réservé",
		field: "slot",
		renderCell: (e) => <CellDate date={e.value} />,
		exportCell: (e) => {
			if (!e) return "";
			const real_date = new Date(e);
			return `${format_date(real_date, "full_short")} | ${format_time(real_date)}`;
		},
		hidden: true,
	},
];

const generate_participation_form_entries_export = (payments, tickets, forms, form_entries) => {
	const before_form = forms.find((form) => form.form_type === "before");
	const after_form = forms.find((form) => form.form_type === "after");
	const before_form_id = before_form ? before_form.id : 0;

	const export_entries = {};

	for (let entries = 0; entries < form_entries.length; entries++) {
		const entry = form_entries[entries];
		const linked_payment = payments.find((payment) => payment.id === entry.payment);
		const linked_ticket = tickets.find((ticket) => ticket.id === entry.ticket);
		const export_entry = {
			email: linked_ticket ? linked_ticket.email : "",
			first_name: linked_ticket ? linked_ticket.first_name : linked_payment.first_name,
			last_name: linked_ticket ? linked_ticket.last_name : linked_payment.last_name,
			payment: linked_payment.str_id,
			ticket: linked_ticket ? linked_ticket.str_id : "order",
			answers: JSON.parse(entry.answers),
			is_before: entry.participation_form === before_form_id,
		};
		if (export_entries[entry.payment]) {
			export_entries[entry.payment].push(export_entry);
		} else {
			export_entries[entry.payment] = [export_entry];
		}
	}

	const export_list = Object.values(export_entries);
	const data = [];

	const all_questions = new Set();

	for (let id = 0; id < export_list.length; id++) {
		const payment = export_list[id];
		const already_processed = [];
		for (let index = 0; index < payment.length; index++) {
			if (already_processed.includes(index)) continue;
			const entry = payment[index];
			const all_entry_same_ticket = payment.filter((e) => e.ticket === entry.ticket);
			let final_entry = {
				...entry,
			};
			delete final_entry.answers;
			all_entry_same_ticket.forEach((e) => {
				already_processed.push(payment.indexOf(e));
				for (let answer = 0; answer < e.answers.length; answer++) {
					const a = e.answers[answer];
					const q = `${e.is_before ? "pre" : "post"} | ${a.question}`;
					all_questions.add(q);
					if (a.answer) {
						final_entry[q] = a.answer;
					}
				}
			});
			data.push(final_entry);
		}
	}

	const columnWidths = data[0]
		? Object.keys(data[0]).map((key) => {
				// Adjust the width of each column based on the maximum length of the values in the column
				const maxLength = data.reduce(
					(max, row) => Math.max(max, row[key] ? row[key].toString().length : 0),
					key.length
				);
				return { wch: maxLength + 5 }; // Add some padding
		  })
		: [];

	const worksheet = XLSX.utils.json_to_sheet(data);
	worksheet["!cols"] = columnWidths;
	const workbook = XLSX.utils.book_new();
	const now = new Date().toLocaleDateString("fr-FR", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	});
	XLSX.utils.book_append_sheet(workbook, worksheet, "Form Entry");
	XLSX.writeFile(workbook, `form_entry_export_${now}.xlsx`);
};

const EventParticipants = () => {
	const { event_id } = useParams();
	const navigate = useNavigate();
	const { event, load_participants } = useContext(CurrentEventContext);

	useEffect(() => {
		if (event?.id) {
			load_participants(parseInt(event_id));
		}
	}, [event]);

	if (!event?.participants?.length) {
		return (
			<DefaultView>
				<EmptyView
					height="calc(100vh - 120px)"
					title="No participants"
					description="We don't have any participants for your event yet. Don't hesitate to share the link to this event."
				/>
			</DefaultView>
		);
	}

	return (
		<DefaultView>
			<div id="event-participants">
				<div className="export-form">
					<span
						className=""
						onClick={() =>
							generate_participation_form_entries_export(
								event.payments,
								event.participants,
								event.participation_forms,
								event.participation_form_entries
							)
						}
					>
						Télécharger les données des formulaires
					</span>
				</div>
				<PassPassTable
					columns={columns.map((column, index) => {
						column.order = index;
						return column;
					})}
					pageSizeOptions={[10, 20, 50, 100]}
					title="Participants"
					data={event?.participants ? [...event.participants].sort((a, b) => b.id - a.id) : []}
				/>
			</div>
		</DefaultView>
	);
};

export default EventParticipants;
