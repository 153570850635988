import React, { useEffect, useState } from "react";
import "./style.scss";
import { Modal } from "@mui/material";
import { Input, Select, Button, DatePicker } from "antd";
import dayjs from "dayjs";

const { TextArea } = Input;

const TIcketUpdateModal = ({ open, handleClose, ticket, is_new, saveTicket, event }) => {
	const [updatableTicket, setUpdatableTicket] = useState(ticket);
	const [availability, setAvailability] = useState("always");
	const [amountPerOrder, setAmountPerOrder] = useState(false);

	useEffect(() => {
		if (ticket) {
			if (ticket.min_per_order || ticket.max_per_order) {
				setAmountPerOrder(true);
			}
			if (ticket.sell_end_at || ticket.sell_start_at) {
				setAvailability("date");
			}
			setUpdatableTicket(ticket);
		}
	}, [ticket]);

	const handleSave = () => {
		const ticket = { ...updatableTicket };
		if (availability === "always") {
			ticket.sell_end_at = null;
			ticket.sell_start_at = null;
		}
		if (!amountPerOrder) {
			ticket.max_per_order = 0;
			ticket.min_per_order = 0;
		}
		saveTicket(updatableTicket);
	};

	const update_availibility = (status) => {
		if (status === "always") {
			setUpdatableTicket({
				...updatableTicket,
				sell_start_at: null,
				sell_end_at: null,
			});
		}
		setAvailability(status);
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			style={{ alignItems: "center", justifyContent: "center", display: "flex" }}
		>
			{updatableTicket !== null && updatableTicket !== undefined ? (
				<div className="tickets-addon-modal">
					<div className="modal-header">
						<span className="header-title">{is_new ? "Ajouter un billet" : "Modifier un billet"}</span>
						<Select
							value={updatableTicket.visibility === "" ? "visible" : updatableTicket.visibility}
							style={{ minWidth: "200px" }}
							onChange={(e) =>
								setUpdatableTicket({
									...updatableTicket,
									visibility: e === "visible" ? "" : e,
								})
							}
							options={[
								{ label: "Visible", value: "visible" },
								{ label: "Hidden", value: "hidden" },
								{ label: "Visible if availible", value: "availible" },
							]}
						/>
					</div>
					<div className="modal-content">
						<div className="ticket-setting-block">
							<span className="ticket-setting-title">Details</span>
							<div className="ticket-setting-content">
								<div className="ticket-setting-input-div">
									<label>Nom du billet</label>
									<Input
										value={updatableTicket.title}
										placeholder="Nom du billet"
										onChange={(e) =>
											setUpdatableTicket({
												...updatableTicket,
												title: e.target.value,
											})
										}
									/>
								</div>
								<div className="ticket-setting-input-div">
									<label>Quantité disponible</label>
									<Input
										type="number"
										value={updatableTicket.amount}
										placeholder="Quantité disponible"
										onChange={(e) =>
											setUpdatableTicket({
												...updatableTicket,
												amount: e.target.value,
											})
										}
									/>
								</div>
								<div className="ticket-setting-input-div">
									<label>Prix (0 pour gratuit)</label>
									<Input
										type="number"
										value={updatableTicket.price / 100}
										placeholder="Quantité disponible"
										onChange={(e) =>
											setUpdatableTicket({
												...updatableTicket,
												price: e.target.value * 100,
											})
										}
									/>
								</div>
							</div>
						</div>
						<div className="ticket-setting-block">
							<span className="ticket-setting-title">Description</span>
							<div className="ticket-setting-content">
								<div className="ticket-setting-input-div full-width">
									<label>Description</label>
									<TextArea
										rows={4}
										placeholder="Description"
										value={updatableTicket.description}
										onChange={(e) =>
											setUpdatableTicket({
												...updatableTicket,
												description: e.target.value,
											})
										}
									/>
								</div>
							</div>
						</div>
						<div className="ticket-setting-block">
							<span className="ticket-setting-title">Disponibilité</span>
							<div className="ticket-setting-content">
								<div className="ticket-setting-input-div">
									<label>Disponibilité</label>
									<Select
										value={availability}
										size="small"
										onChange={(e) => update_availibility(e)}
										options={[
											{ label: "Toujours", value: "always" },
											{ label: "Date", value: "date" },
										]}
									/>
								</div>
								{availability !== "always" && (
									<>
										<div className="ticket-setting-input-div">
											<label htmlFor="event-start-at">Début de la vente</label>
											<DatePicker
												allowClear={false}
												id="event-start-at"
												onChange={(date, dateString) =>
													setUpdatableTicket({
														...updatableTicket,
														sell_start_at: date?.toDate(),
													})
												}
												className="classic-date-input"
												value={
													updatableTicket.sell_start_at
														? dayjs(updatableTicket.sell_start_at)
														: null
												}
												picker="date"
												format={"YYYY-MM-DD HH:mm"}
												showTime
											/>
										</div>
										<div className="ticket-setting-input-div">
											<label htmlFor="event-start-at">Fin de la vente</label>
											<DatePicker
												allowClear={false}
												id="event-end-at"
												onChange={(date, dateString) =>
													setUpdatableTicket({
														...updatableTicket,
														sell_end_at: date?.toDate(),
													})
												}
												className="classic-date-input"
												value={
													updatableTicket.sell_end_at
														? dayjs(updatableTicket.sell_end_at)
														: null
												}
												picker="date"
												format={"YYYY-MM-DD HH:mm"}
												showTime
											/>
										</div>
									</>
								)}
							</div>
						</div>
						<div className="ticket-setting-block">
							<span className="ticket-setting-title">Quantité par commande</span>
							<div className="ticket-setting-content">
								<div className="ticket-setting-input-div">
									<label>Quantité par commande</label>
									<Select
										value={amountPerOrder}
										size="small"
										onChange={(e) => setAmountPerOrder(e)}
										options={[
											{ label: "Quantite libre", value: false },
											{ label: "Quantite min / max", value: true },
										]}
									/>
								</div>
								{amountPerOrder && (
									<>
										<div className="ticket-setting-input-div">
											<label htmlFor="event-start-at">Quantité minimale</label>
											<Input
												type="number"
												value={updatableTicket.min_per_order}
												onChange={(e) =>
													setUpdatableTicket({
														...updatableTicket,
														min_per_order: e.target.value,
													})
												}
											/>
										</div>
										<div className="ticket-setting-input-div">
											<label htmlFor="event-start-at">Quantité maximale</label>
											<Input
												type="number"
												value={updatableTicket.max_per_order}
												onChange={(e) =>
													setUpdatableTicket({
														...updatableTicket,
														max_per_order: e.target.value,
													})
												}
											/>
										</div>
									</>
								)}
							</div>
						</div>
					</div>

					<div className="modal-footer">
						<Button onClick={handleClose}>fermer</Button>
						<Button
							onClick={() => handleSave(updatableTicket)}
							disabled={
								JSON.stringify(ticket) === JSON.stringify(updatableTicket) ||
								updatableTicket.title === ""
							}
							className="main"
							type="primary"
						>
							Continuer
						</Button>
					</div>
				</div>
			) : (
				<div></div>
			)}
		</Modal>
	);
};

export default TIcketUpdateModal;
