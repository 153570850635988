import { Button, Skeleton } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import CurrentEventContext from "../../../contexts/CurrentEventContext";
import { generate_image_path } from "../../../helpers/images";
import PageLoading from "components/main/page_loading/PageLoading";
import DashboardIcon from "../../../assets/static/icon-stats.svg";
import OrderIcon from "../../../assets/static/orders-icon.svg";
import UsersIcon from "../../../assets/static/icon-users.svg";
import MemberIcon from "../../../assets/static/icon-membre.svg";
import EntriesIcon from "../../../assets/static/in-out-icon.svg";
import AuthContext from "contexts/AuthContext";

const EventNavigationItem = ({ title, pathname, icon, includes = false }) => {
	const location = useLocation();
	const { event_id } = useParams();
	const path = `/events/${event_id}` + (pathname === "" ? "" : `/${pathname}`);

	return (
		<li
			className={
				(!includes && location.pathname === path) || (includes && location.pathname.includes(path))
					? "active hov"
					: "hov"
			}
		>
			<Link to={path}>
				<div className="account-nav-elem">
					<img src={icon}></img>
					<span>{title}</span>
				</div>
			</Link>
		</li>
	);
};

const DefaultView = ({ children, visible = "", save, reset, is_valid }) => {
	const location = useLocation();
	const { event_id } = useParams();
	const { user } = useContext(AuthContext);
	const { event, load_event, clear_event } = useContext(CurrentEventContext);
	const navigate = useNavigate();

	useEffect(() => {
		load_event(event_id);
	}, [event_id]);

	useEffect(() => {
		if (event && event.id && user?.account?.id && user.account.id !== event.organizer && event.id === event_id) {
			navigate("/events");
		}
	}, [user, event]);

	return (
		<div id="event-base-view">
			<ul className={`event-navigation ${visible === "always" ? "full" : "aside"}`}>
				<li className="event-title-container">
					{event && event.id === parseInt(event_id) ? (
						<>
							<img src={generate_image_path(event_id, event.main_picture, "phone")} />
							<span className="one-line-text">{event.title}</span>
						</>
					) : (
						<div className="title-skeleton">
							<Skeleton variant="rectangular" width={20} />
							<Skeleton variant="text" width={120} />
						</div>
					)}
				</li>
				<div className="event-menu">
					<EventNavigationItem icon={DashboardIcon} title={"Dashboard"} pathname={""} />
					<EventNavigationItem icon={MemberIcon} title={"Équipe"} pathname={"members"} />
					<EventNavigationItem
						icon={UsersIcon}
						title={"Participants"}
						pathname={"participants"}
						includes={true}
					/>
					<EventNavigationItem icon={EntriesIcon} title={"Entrées"} pathname={"entries"} />
					<EventNavigationItem icon={OrderIcon} title={"Commandes"} pathname={"orders"} includes={true} />
				</div>
			</ul>

			{event && event.id === parseInt(event_id) ? (
				!save ? (
					<>{children}</>
				) : (
					<>
						{children}
						<div className="save-changes">
							<Button onClick={reset} variant="text">
								reset
							</Button>
							<Button onClick={save} disabled={!is_valid()} variant="contained">
								Save
							</Button>
						</div>
					</>
				)
			) : (
				<PageLoading height="calc(100vh - 120px)" />
			)}
		</div>
	);
};

export default DefaultView;
