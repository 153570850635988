import React, { useContext, useEffect, useRef } from "react";
import { createContext, useState } from "react";
import axios, { set_instance_token, unset_instance_token } from "../services/axios";
import { useLocation, useParams } from "react-router-dom";
import PageLoading from "components/main/page_loading/PageLoading";
import AuthContext from "./AuthContext";
import { format_date, format_time } from "helpers/format";
import { entries_refactor, orders_refactof, participants_refactor } from "helpers/entities";

const CurrentEventContext = createContext();

export const CurrentEventProvider = (props) => {
	const [event, setEvent] = useState(null);
	const { user } = useContext(AuthContext);
	const location = useLocation();

	const load_event = async (event_id) => {
		if (event_id !== event?.id) {
			const event_data = await axios
				.get(`/api/dashboard/${user.account.id}/events/${event_id}`)
				.then((e) => e.data)
				.catch((e) => null);
			if (event_data) {
				event_data.participants = participants_refactor(event_data.participants, event_id, event_data);
				event_data.payments = orders_refactof(event_data.payments);
				setEvent({ ...event_data, members: [], entries: null });
			}
		}
	};

	const clear_event = () => {
		setEvent(null);
	};

	const load_participants = async (event_id) => {
		if (event_id === event?.id && event?.participants !== null) return;

		const participants = await axios
			.get(`/api/events/${event_id}/participants`)
			.then((e) => e.data)
			.catch((e) => null);
		if (participants !== null) {
			participants.forEach((element) => {
				element.full_name = `${element.first_name} ${element.last_name}`;
			});
			setEvent({ ...event, participants: participants });
		}
	};

	const load_entries = async (event_id) => {
		if (event_id === event.id && event.entries !== null) return;

		const entries = await axios
			.get(`/api/events/${event_id}/entries`)
			.then((e) => e.data)
			.catch((e) => null);
		if (entries !== null) {
			setEvent({
				...event,
				entries: entries_refactor(entries).sort((a, b) => b.v_at - a.v_at),
			});
		}
	};

	return (
		<CurrentEventContext.Provider
			value={{
				event,
				load_event,
				load_participants,
				load_entries,
				clear_event,
			}}
		>
			{props.children}
		</CurrentEventContext.Provider>
	);
};

export default CurrentEventContext;
