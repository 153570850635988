import React, { useContext, useState } from "react";
import "./style.scss";
import DefaultView from "../../../components/account_settings/default_view/DefaultView";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import AuthContext from "../../../contexts/AuthContext";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import axios from "../../../services/axios";
import { TOAST_LVL } from "constants/constants";
import { App } from "antd";

const GeneralAccountSettings = () => {
	const { t } = useTranslation("account_settings");
	const { user, update_account } = useContext(AuthContext);
	const { notification } = App.useApp();

	const [settingsData, setSettingsData] = useState({
		title: user.account.title,
	});

	const handle_change = (e) => {
		setSettingsData({ ...settingsData, prefix: e.target.value });
	};

	const save = async () => {
		const response = await axios
			.put(`/api/organizers/${user.account.id}`, {
				title: settingsData.title,
				description: user.account.description,
				address_label: "",
				address_latitude: 0,
				address_longitude: 0,
				is_private: user.account.is_private,
				main_picture: user.account.main_picture,
				cover_picture: user.account.cover_picture,
				page_url: user.account.page_url,
			})
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			update_account({ title: response.title });

			notification.success({
				message: "Parameters updated",
				description: `The access rights on ${user.account.name} have been updated`,
				placement: "bottomLeft",
			});
		} else {
			reset();
			notification.error({
				message: "Update error",
				description: "You do not have sufficient rights to perform this action",
				placement: "bottomLeft",
			});
		}
	};

	const reset = () => {
		setSettingsData({
			title: user.account.title,
		});
	};

	const valid = () => {
		return settingsData.title !== user.account.title;
	};

	return (
		<DefaultView save={save} reset={reset} is_valid={valid}>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>
							{t("General settings", {
								account_name: user.account.title,
							})}
						</h1>
						<p>
							{t("Change how account work", {
								account_name: user.account.title,
							})}
						</p>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>{t("Account name")}</h2>
							<p>{t("Account name explaination")}</p>
						</div>
						<div className="setting-block-content">
							<TextField
								sx={{ minWidth: "240px" }}
								id="outlined-basic"
								value={settingsData.title}
								onChange={(e) =>
									setSettingsData({
										...settingsData,
										title: e.target.value,
									})
								}
								label={t("Account name")}
								variant="outlined"
							/>
						</div>
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default GeneralAccountSettings;
