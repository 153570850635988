import { format_date, format_time } from "helpers/format";
import React from "react";

const CellDate = ({ date }) => {
	if (date === "" || !date || date === "Not validated") {
		return <span className={`table-value-date empty`}>-</span>;
	}
	const real_date = new Date(date);
	const date_string = `${format_date(real_date, "full_short")} | ${format_time(real_date)}`;
	return <span className={`table-value-date`}>{date_string}</span>;
};

export default CellDate;
