import { Button, Modal, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { Input, Select } from "antd";

const CreateUpdateFormModal = ({ forms, selected, close, update, create }) => {
	const [currentForm, setCurrentForm] = useState({
		title: "",
		questions: [],
		event: 1,
	});

	useEffect(() => {
		if (selected !== null) {
			const selected_form = forms.find((e) => e.id === selected);
			if (selected_form) {
				setCurrentForm({
					...selected_form,
					questions: JSON.parse(selected_form.questions),
					each_attendee: false,
				});
			} else {
				setCurrentForm({
					...selected_form,
					title: "New form",
					questions: [
						{
							required: false,
							question: "",
							type: "short-text",
						},
					],
				});
			}
		} else {
			setCurrentForm({
				title: "",
				questions: [],
				event: 1,
			});
		}
	}, [selected]);

	const update_question = (data, index) => {
		const questions = currentForm.questions;
		questions.splice(index, 1, data);
		setCurrentForm({ ...currentForm, questions: questions });
	};

	const new_question = () => {
		const questions = currentForm.questions;
		questions.push({
			required: true,
			question: "",
			type: "short-text",
		});
		setCurrentForm({ ...currentForm, questions: questions });
	};

	const set_question_status = (question, index, status) => {
		if (status === "delete") {
			const questions = currentForm.questions;
			questions.splice(index, 1);
			setCurrentForm({ ...currentForm, questions: questions });
		} else {
			const questions = currentForm.questions;
			questions.splice(index, 1, {
				...question,
				required: status === "required" ? true : false,
			});
			setCurrentForm({ ...currentForm, questions: questions });
		}
	};

	if (!selected) return null;

	return (
		<Modal className="create-update-form-modal" open={selected !== null} onClose={close}>
			<div className="create-update-form-center">
				<div className="create-update-header">
					<span>{currentForm.id ? "Modification du formulaire" : "Création d'un nouveau formulaire"}</span>
				</div>
				<div className="create-update-content">
					<Input
						value={currentForm.title}
						onChange={(e) => setCurrentForm({ ...currentForm, title: e.target.value })}
						placeholder="Titre du formulaire"
						className="input-title-form"
					/>
					<Button onClick={new_question} variant="outlined">
						New question
					</Button>
					<div className="questions-container">
						{currentForm.questions.map((question, index) => (
							<div key={index} className="question-item">
								<span>Question {index + 1}</span>
								<div className="question-form">
									<Input
										value={question.question}
										onChange={(e) =>
											update_question({ ...question, question: e.target.value }, index)
										}
										placeholder="Entrez ici votre question"
									/>
									<Select
										onChange={(e) => update_question({ ...question, type: e }, index)}
										value={question.type}
									>
										<Select.Option value="short-text">Short Text</Select.Option>
										<Select.Option value="long-text">Long Text</Select.Option>
									</Select>
									<Select
										onChange={(e) => set_question_status(question, index, e)}
										value={question.required ? "required" : "optional"}
									>
										<Select.Option value="required">Required</Select.Option>
										<Select.Option value="optional">Optional</Select.Option>
										<Select.Option value="delete">Delete</Select.Option>
									</Select>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="create-update-footer">
					<Button variant="outlined" onClick={close}>
						Cancel
					</Button>
					<Button
						variant="contained"
						onClick={() => (currentForm.id ? update(currentForm) : create(currentForm))}
					>
						{!currentForm.id ? "Creer le nouveau formulaire" : "Mettre a jour le formulaire"}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default CreateUpdateFormModal;
