import { LoadingButton } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import axios from "../../../../services/axios";
import AuthContext from "../../../../contexts/AuthContext";
import ConfirmationContext from "../../../../contexts/ConfirmationContext";
import { TOAST_LVL } from "../../../../constants/constants";
import "./style.scss";
import { App } from "antd";

function validateEmail(email) {
	// Regular expression pattern for email validation
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Check if the email matches the pattern
	if (emailPattern.test(email)) {
		return true; // Valid email
	} else {
		return false; // Invalid email
	}
}

const NewMember = ({ add_member, close, active, is_event = false, event_id = 0 }) => {
	const { user } = useContext(AuthContext);
	const [userEmail, setUserEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const { notification } = App.useApp();

	const add_user = async () => {
		setLoading(true);
		let error = -1;
		const ret = await axios
			.post(is_event ? `/api/events/${event_id}/accesses` : `/api/organizers/${user.account.id}/accesses`, {
				email: userEmail,
			})
			.then((e) => e.data)
			.catch((e) => {
				error = e.response.status;
				return null;
			});
		if (ret) {
			setLoading(false);
			setUserEmail("");
			add_member();
			close();
		} else {
			setLoading(false);
			if (error === 403) {
				notification.error({
					message: "Create error",
					description: "You do not have sufficient rights to perform this action",
					placement: "bottomLeft",
				});
			} else {
				notification.error({
					message: "Not found",
					description: "This email is not valid",
					placement: "bottomLeft",
				});
			}
		}
	};

	if (!active) {
		return null;
	}

	return (
		<div id="new-key-div" className="pair-device">
			<div className="pair-device-center">
				<div className="pair-device-header">
					<h3>Ajouter un membre</h3>
				</div>
				<div className="pair-device-body">
					<p>
						Saisissez l'adresse e-mail du nouvel utilisateur. C'est avec cette adresse e-mail que
						l'utilisateur se connectera à son compte.
					</p>
					<TextField
						value={userEmail}
						onChange={(e) => setUserEmail(e.target.value)}
						size="small"
						fullWidth
						label="Email du membre"
					/>
				</div>
				<div className="pair-device-footer">
					<Button onClick={close} className="close-button">
						Annuler
					</Button>
					<LoadingButton
						loading={loading}
						disabled={!validateEmail(userEmail)}
						onClick={() => add_user()}
						className="add-button"
					>
						Ajouter le membre
					</LoadingButton>
				</div>
			</div>
		</div>
	);
};

export default NewMember;
