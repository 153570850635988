import React from "react";
import "./style.scss";
import { Link, useParams } from "react-router-dom";

const RenderUser = ({ user }) => {
	const { event_id } = useParams();
	let user_text = "";
	let user_status = "";

	if (user.first_name) {
		user_text = `${user.first_name} ${user.last_name}`;
	} else if (user.email && user.email.includes("@")) {
		user_text = user.email;
	} else {
		user_text = "Invité";
	}

	return (
		<Link to={`/events/${event_id}/participants/${user.id}`} className="render-user">
			{user_text}
		</Link>
	);
};

export default RenderUser;
