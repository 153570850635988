import { Button, Checkbox, DatePicker, Input, Select, Space } from "antd";
import LabelContent from "components/main/label_content/LabelContent";
import Modal from "components/main/modal/Modal";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./style.scss";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const PromoCodeFormModal = ({ selected, close, tickets, save }) => {
	const [ticketsState, setTicketsState] = useState(null);
	const [baseValue, setBaseValue] = useState({
		code: "",
		discount_type: "value",
		discount: 0,
		ticket_limit: -1,
		start_at: new Date(new Date().setMinutes(0, 0, 0)),
		end_at: null,
		tickets: "all",
	});
	const [showMultiple, setShowMultiple] = useState(false);
	const [keepSameValue, setKeepSameValue] = useState(true);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (selected.item) {
			const first = selected.item[0];
			setShowMultiple(selected.item.length > 1 || selected.item[0].tickets !== "all");
			setKeepSameValue(selected.item.length === 1);
			setBaseValue({
				code: first.code,
				discount_type: first.discount_type,
				discount: first.discount,
				ticket_limit: first.ticket_limit,
				start_at: first.start_at,
				end_at: first.end_at,
				tickets: first.tickets,
			});
			setTicketsState(JSON.parse(JSON.stringify(selected.item.toSorted((a, b) => a.id - b.id))));
		}
	}, [selected]);

	const update_specific_ticket = (id, updated) => {
		const old_items = [...ticketsState];
		let old_item = ticketsState[id];
		old_item = { ...old_item, ...updated };
		old_items.splice(id, 1, old_item);
		setTicketsState(old_items);
	};

	const build_save = async () => {
		let codes = [];
		if (keepSameValue) {
			const code = { ...ticketsState[0], ...baseValue };
			code.discount = parseInt(code.discount);
			if (!showMultiple) {
				code.tickets = "all";
				if (!code.start_at) {
					code.start_at = new Date(new Date().setMinutes(0, 0, 0));
				}
			}
			codes = [code];
		} else {
			codes = [...ticketsState];
			codes.forEach((code) => {
				code.code = baseValue.code;
				code.discount = parseInt(code.discount);
				if (!code.start_at) {
					code.start_at = new Date(new Date().setMinutes(0, 0, 0));
				}
			});
		}
		setLoading(true);
		await save(codes);
		setLoading(false);
	};

	return (
		<Modal
			loading={loading}
			maxWidth="1200px"
			open={selected.item !== null}
			is_valid={true}
			onClose={close}
			cancel={close}
			save={build_save}
			save_message={"Sauvegarder"}
			title={"Modifier le code promotionnel"}
		>
			<div className="promo-code-modal">
				<div className="promo-code-modal-top">
					<LabelContent label_value={"Code"}>
						<Input
							value={baseValue.code}
							onChange={(e) => setBaseValue({ ...baseValue, code: e.target.value })}
						/>
					</LabelContent>

					<LabelContent disabled={!keepSameValue} label_value={"Valeur"}>
						<Input
							disabled={!keepSameValue}
							addonBefore={
								<Select
									disabled={!keepSameValue}
									value={baseValue.discount_type}
									onChange={(e) => setBaseValue({ ...baseValue, discount_type: e })}
									options={[
										{ value: "value", label: "€" },
										{ value: "percent", label: "%" },
									]}
								/>
							}
							type="number"
							value={baseValue.discount}
							onChange={(e) => setBaseValue({ ...baseValue, discount: e.target.value })}
						/>
					</LabelContent>
					<LabelContent disabled={!keepSameValue} label_value={"Utilisations max"}>
						<Space.Compact>
							<Select
								value={baseValue.ticket_limit}
								disabled={!keepSameValue}
								onChange={(e) => setBaseValue({ ...baseValue, ticket_limit: e })}
								options={[
									{ value: -1, label: "Illimité" },
									{
										value: baseValue.ticket_limit === -1 ? 1 : baseValue.ticket_limit,
										label: "Limité",
									},
								]}
							/>
							{baseValue.ticket_limit !== -1 && (
								<Input
									disabled={!keepSameValue}
									type="number"
									value={baseValue.ticket_limit}
									onChange={(e) => {
										if (!isNaN(e.target.value)) {
											setBaseValue({
												...baseValue,
												ticket_limit:
													parseInt(e.target.value) < 1 ? 1 : parseInt(e.target.value),
											});
										} else {
											setBaseValue({ ...baseValue, ticket_limit: e.target.value });
										}
									}}
								/>
							)}
						</Space.Compact>
					</LabelContent>
					<LabelContent disabled={!keepSameValue} label_value={"Dates de disponibilité"}>
						<DatePicker.RangePicker
							disabled={!keepSameValue}
							style={{ width: "300px" }}
							picker="date"
							format={"YYYY-MM-DD HH:mm"}
							showTime
							value={[
								baseValue.start_at ? dayjs(baseValue.start_at) : null,
								baseValue.end_at ? dayjs(baseValue.end_at) : null,
							]}
							placeholder={["", "Fin de la vente"]}
							allowEmpty={[false, true]}
							onChange={(date, dateString) => {
								if (date)
									setBaseValue({
										...baseValue,
										start_at: date[0].toDate(),
										end_at: date[1] ? date[1].toDate() : null,
									});
								else
									setBaseValue({
										...baseValue,
										start_at: null,
										end_at: null,
									});
							}}
						/>
					</LabelContent>
				</div>
				<div className="checkbox-container">
					<Checkbox checked={showMultiple} onChange={() => setShowMultiple(!showMultiple)}>
						Associer le code à des tickets spécifiques
					</Checkbox>
					{showMultiple && (
						<Checkbox checked={!keepSameValue} onChange={() => setKeepSameValue(!keepSameValue)}>
							Valeur spécifique pour chaque ticket
						</Checkbox>
					)}
				</div>
				{showMultiple &&
					(keepSameValue ? (
						<div className={"multiple-select"}>
							<LabelContent label_value={"Sélectionnez les tickets à associer"}>
								<Select
									style={{ width: "100%" }}
									mode="multiple"
									value={
										baseValue.tickets === "all"
											? []
											: baseValue.tickets
													.substring(1, baseValue.tickets.length - 1)
													.split("|")
													.map((e) => parseInt(e))
									}
									onChange={(e) => {
										setBaseValue({ ...baseValue, tickets: !e.length ? "all" : `|${e.join("|")}|` });
									}}
									options={tickets.map((ticket) => {
										return { value: ticket.id, label: ticket.title };
									})}
								/>
							</LabelContent>
						</div>
					) : (
						<div className="multiple-wrapper">
							{ticketsState?.map((code, id) => (
								<div className="code-form" key={id}>
									<Select
										value={
											code.tickets === "all" ||
											code.tickets.length < 3 ||
											code.tickets.substring(1, code.tickets.length - 1).indexOf("|") !== -1
												? ""
												: parseInt(code.tickets.substring(1, code.tickets.length - 1))
										}
										onChange={(e) => update_specific_ticket(id, { tickets: `|${e}|` })}
										options={tickets
											.filter((t) => {
												const other_list = ticketsState.filter((e) => e.id !== code.id);
												const existing = other_list.filter(
													(e) => e.tickets.indexOf(t.id.toString()) !== -1
												);
												if (existing.length) {
													return false;
												}

												return true;
											})
											.map((ticket) => {
												return { value: ticket.id, label: ticket.title };
											})}
									/>
									<Input
										addonBefore={
											<Select
												value={code.discount_type}
												onChange={(e) => update_specific_ticket(id, { discount_type: e })}
												options={[
													{ value: "value", label: "€" },
													{ value: "percent", label: "%" },
												]}
											/>
										}
										type="number"
										value={code.discount}
										onChange={(e) => update_specific_ticket(id, { discount: e.target.value })}
									/>
									<Space.Compact>
										<Select
											value={code.ticket_limit}
											onChange={(e) => update_specific_ticket(id, { ticket_limit: e })}
											options={[
												{ value: -1, label: "Illimité" },
												{
													value: code.ticket_limit === -1 ? 1 : code.ticket_limit,
													label: "Limité",
												},
											]}
										/>
										{code.ticket_limit !== -1 && (
											<Input
												type="number"
												value={code.ticket_limit}
												onChange={(e) => {
													if (!isNaN(e.target.value)) {
														update_specific_ticket(id, {
															ticket_limit:
																parseInt(e.target.value) < 1
																	? 1
																	: parseInt(e.target.value),
														});
													} else {
														update_specific_ticket(id, {
															ticket_limit: e.target.value,
														});
													}
												}}
											/>
										)}
									</Space.Compact>
									<DatePicker.RangePicker
										style={{ width: "300px" }}
										picker="date"
										format={"YYYY-MM-DD HH:mm"}
										showTime
										value={[
											code.start_at ? dayjs(code.start_at) : null,
											code.end_at ? dayjs(code.end_at) : null,
										]}
										placeholder={["", "Fin de la vente"]}
										allowEmpty={[false, true]}
										onChange={(date, dateString) => {
											if (date)
												update_specific_ticket(id, {
													start_at: date[0].toDate(),
													end_at: date[1] ? date[1].toDate() : null,
												});
											else
												update_specific_ticket(id, {
													start_at: null,
													end_at: null,
												});
										}}
									/>
									<div style={{ width: "40px" }}>
										<Button
											onClick={() => {
												if (ticketsState.length <= 1) {
													setKeepSameValue(true);
												} else {
													setTicketsState(ticketsState.filter((e) => e.id !== code.id));
												}
											}}
											icon={<DeleteOutlined style={{ color: "red" }} />}
										/>
									</div>
								</div>
							))}
							<Button
								onClick={() =>
									setTicketsState([
										...ticketsState,
										{
											event: 2,
											tickets: "all",
											target: "",
											code: "",
											ticket_limit: -1,
											discount: 0,
											discount_type: "percent",
											start_at: new Date(new Date().setMinutes(0, 0, 0)),
											end_at: null,
										},
									])
								}
								disabled={ticketsState.length >= tickets.length}
								style={{ width: "fit-content" }}
								icon={<PlusOutlined />}
								type="text"
							>
								Ajouter une option
							</Button>
						</div>
					))}
			</div>
		</Modal>
	);
};

export default PromoCodeFormModal;
