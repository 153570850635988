import { Checkbox, IconButton, TextField } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultView from "../../../components/account_settings/default_view/DefaultView";
import AuthContext from "../../../contexts/AuthContext";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "../../../services/axios";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import "./style.scss";
import { generate_image_path } from "../../../helpers/images";
import { TOAST_LVL } from "constants/constants";
import ConfirmationContext from "contexts/ConfirmationContext";
import { App } from "antd";

const PublicProfile = () => {
	const { t, i18n } = useTranslation("account_settings");
	const { user, update_account } = useContext(AuthContext);
	const { setToast } = useContext(ConfirmationContext);
	const uniqueness_timout = useRef(null);
	const [reload, setReload] = useState(false);
	const [loading, setLoading] = useState(false);
	const [publicProfile, setPublicProfile] = useState({
		title: user.account.title,
		page_url: user.account.page_url,
		website_url: user.account.website_url ? user.account.website_url : "",
		description: user.account.description ? user.account.description : "",
		facebook_id: user.account.facebook_id ? user.account.facebook_id : "",
		twitter_id: user.account.twitter_id ? user.account.twitter_id : "",
		instagram_id: user.account.instagram_id ? user.account.instagram_id : "",
		linkedin_id: user.account.linkedin_id ? user.account.linkedin_id : "",
		main_picture: user.account.main_picture ? user.account.main_picture : "",
		cover_picture: user.account.cover_picture ? user.account.cover_picture : "",
	});
	const [uniqueness, setUniqueness] = useState({
		title: true,
		page_url: true,
	});
	const mainPicture = useRef(null);
	const coverPicture = useRef(null);
	const { notification } = App.useApp();

	const save = async () => {
		setLoading(true);
		const response = await axios
			.put(`/api/organizers/${user.account.id}`, {
				...publicProfile,
				address_label: "",
				address_latitude: 0,
				address_longitude: 0,
				is_private: false,
				main_picture: user.account.main_picture,
			})
			.then((e) => e.data)
			.catch((e) => null);
		let main_picture = publicProfile.main_picture;
		let cover_picture = publicProfile.cover_picture;
		if (response) {
			if (
				(publicProfile.main_picture === "" && mainPicture.current) ||
				(publicProfile.cover_picture === "" && coverPicture.current)
			) {
				var bodyFormData = new FormData();
				if (publicProfile.main_picture === "" && mainPicture.current) {
					bodyFormData.append("main_picture", mainPicture.current[0], "hello.png");
				}
				if (publicProfile.cover_picture === "" && coverPicture.current) {
					bodyFormData.append("cover_picture", coverPicture.current[0], "hello.png");
				}
				const response = await axios
					.put(`/api/organizers/${user.account.id}/images`, bodyFormData, {
						headers: { "Content-Type": "multipart/form-data" },
					})
					.then((e) => e.data)
					.catch((e) => {
						console.log(e);
						return null;
					});
				if (response) {
					main_picture = response[0];
					cover_picture = response[1];
				}
			}

			update_account({
				...publicProfile,
				main_picture: main_picture,
				cover_picture: cover_picture,
			});
			setPublicProfile({
				...publicProfile,
				main_picture: main_picture,
				cover_picture: cover_picture,
			});

			notification.success({
				message: "Access updated",
				description: `The access rights on ${user.account.name} have been updated`,
				placement: "bottomLeft",
			});
		} else {
			reset();

			notification.error({
				message: "Update error",
				description: `You do not have sufficient rights to perform this action`,
				placement: "bottomLeft",
			});
		}

		mainPicture.current = null;
		coverPicture.current = null;
		setLoading(false);
	};

	const reset = () => {
		setPublicProfile({
			title: user.account.title,
			page_url: user.account.page_url,
			website_url: user.account.website_url ? user.account.website_url : "",
			description: user.account.description ? user.account.description : "",
			facebook_id: user.account.facebook_id ? user.account.facebook_id : "",
			twitter_id: user.account.twitter_id ? user.account.twitter_id : "",
			instagram_id: user.account.instagram_id ? user.account.instagram_id : "",
			linkedin_id: user.account.linkedin_id ? user.account.linkedin_id : "",
			main_picture: user.account.main_picture ? user.account.main_picture : "",
			cover_picture: user.account.cover_picture ? user.account.cover_picture : "",
		});
		setUniqueness({
			title: true,
			page_url: true,
		});
	};

	const get_images = (input_id, is_main) => {
		const input = document.getElementById(input_id);
		const images = [];
		let end_count = 0;

		for (let index = 0; index < input.files.length; index++) {
			const reader = new FileReader();
			reader.onload = function (event) {
				images.push(event.target.result);
				end_count += 1;
				if (end_count === input.files.length) {
					if (is_main) {
						mainPicture.current = [input.files[index], images[0]];
						if (publicProfile.main_picture) {
							setPublicProfile({ ...publicProfile, main_picture: "" });
						}
					} else {
						coverPicture.current = [input.files[index], images[0]];
						if (publicProfile.cover_picture) {
							setPublicProfile({ ...publicProfile, cover_picture: "" });
						}
					}
					setReload((reload) => !reload);
				}
			};
			reader.readAsDataURL(input.files[index]);
		}
	};

	const check_title_uniqueness = (title) => {
		clearTimeout(uniqueness_timout.current);
		uniqueness_timout.current = setTimeout(async () => {
			const response = await axios
				.post(`/api/organizers/${user.account.id}/uniqueness`, {
					title: title,
				})
				.then((e) => e.data)
				.catch((e) => null);
			if (response) {
				setUniqueness({ ...uniqueness, title: true });
			} else {
				setUniqueness({ ...uniqueness, title: false });
			}
		}, 400);
	};

	const check_url_uniqueness = (page_url) => {
		clearTimeout(uniqueness_timout.current);
		uniqueness_timout.current = setTimeout(async () => {
			const response = await axios
				.post(`/api/organizers/${user.account.id}/uniqueness`, {
					page_url: page_url,
				})
				.then((e) => e.data)
				.catch((e) => null);
			if (response) {
				setUniqueness({ ...uniqueness, page_url: true });
			} else {
				setUniqueness({ ...uniqueness, page_url: false });
			}
		}, 400);
	};

	const valid = () => {
		if (!uniqueness.title || !uniqueness.page_url) {
			return false;
		}
		if (publicProfile.title === "" || publicProfile.page_url === "") {
			return false;
		}
		return (
			JSON.stringify(publicProfile) !=
			JSON.stringify({
				title: user.account.title,
				page_url: user.account.page_url,
				website_url: user.account.website_url ? user.account.website_url : "",
				description: user.account.description ? user.account.description : "",
				facebook_id: user.account.facebook_id ? user.account.facebook_id : "",
				twitter_id: user.account.twitter_id ? user.account.twitter_id : "",
				instagram_id: user.account.instagram_id ? user.account.instagram_id : "",
				linkedin_id: user.account.linkedin_id ? user.account.linkedin_id : "",
				main_picture: user.account.main_picture ? user.account.main_picture : "",
				cover_picture: user.account.cover_picture ? user.account.cover_picture : "",
			})
		);
	};

	return (
		<DefaultView loading={loading} save={save} reset={reset} is_valid={valid} hide_navbar={true}>
			<div id="public-profile" className="settings-content">
				<div className="settings-center need-space">
					<div className="section-dashboard section-dashboard__introduction">
						<h1>Profil de votre organisation</h1>
						<p>
							Bienvenue sur la "page de votre organisation" ! Chaque organisation dispose d'un profil
							exclusif qui, complet, attire l'attention des participants sur votre mission et vos valeurs.
							<br />
							<br /> Ce profil renforce votre visibilité, notoriété et crédibilité.
						</p>
						<Link
							onClick={(e) => {
								if (publicProfile.page_url !== user.account.page_url) {
									e.preventDefault();
								}
							}}
							title={
								publicProfile.page_url !== user.account.page_url ? "Save to validate the new url" : ""
							}
							target="_blank"
							to={`https://passpass.be/organizers/${encodeURIComponent(publicProfile.page_url)}`}
						>{`https://passpass.be/organizers/${encodeURIComponent(publicProfile.page_url)}`}</Link>
					</div>
					<div className="setting-block section-dashboard">
						<div className="setting-block-description">
							<h2>A propos de l'organisation</h2>
							<p>Configurez vos informations et parlez de votre organisation.</p>
						</div>
						<div className="setting-block-content column full-width">
							<TextField
								value={publicProfile.title}
								onChange={(e) => {
									setPublicProfile({ ...publicProfile, title: e.target.value });
									check_title_uniqueness(e.target.value);
								}}
								error={!uniqueness.title || publicProfile.title === ""}
								size="small"
								className="text-field"
								fullWidth
								label="Nom de l'organisation"
							/>
							{(!uniqueness.title || publicProfile.title === "") && (
								<span className="input-error-explaination">
									{publicProfile.title === ""
										? "Organization title cannot be empty"
										: "This organization name is already taken"}
								</span>
							)}
							{
								<TextField
									value={publicProfile.website_url}
									onChange={(e) =>
										setPublicProfile({
											...publicProfile,
											website_url: e.target.value,
										})
									}
									size="small"
									className="text-field"
									fullWidth
									label="URL du site web de l'organisation"
									placeholder="ex: https://passpass.be"
								/>
							}
							<div className="setting-inside-block">
								<h3>URL du profil de votre organisation</h3>
								<p>
									Personnaliser votre URL augmente votre crédibilité et facilite la recherche de vos
									événements par les participants. Elle ne peut contenir que des lettres, des
									chiffres, des tirets et des underscores.
								</p>
								<Link
									onClick={(e) => {
										if (publicProfile.page_url !== user.account.page_url) {
											e.preventDefault();
										}
									}}
									title={
										publicProfile.page_url !== user.account.page_url
											? "Save to validate the new url"
											: ""
									}
									target="_blank"
									to={`https://passpass.be/organizers/${encodeURIComponent(publicProfile.page_url)}`}
								>{`https://passpass.be/organizers/${encodeURIComponent(publicProfile.page_url)}`}</Link>
								<TextField
									value={publicProfile.page_url}
									onChange={(e) => {
										setPublicProfile({
											...publicProfile,
											page_url: e.target.value,
										});
										check_url_uniqueness(e.target.value);
									}}
									error={!uniqueness.page_url || publicProfile.page_url === ""}
									size="small"
									className="text-field"
									fullWidth
									label="URL du profil de l'organisation"
								/>
								{(!uniqueness.page_url || publicProfile.page_url === "") && (
									<span className="input-error-explaination">
										{publicProfile.page_url === ""
											? "Organization page URL cannot be empty"
											: "This page URL is already taken"}
									</span>
								)}
							</div>
							<div className="setting-inside-block">
								<h3>Biographie de l'organisation</h3>
								<p>
									Décrivez qui vous êtes, les types d'événements que vous organisez ou votre mission.
									Cette biographie sera affichée sur votre profil d'organisateur.
								</p>
								<div className="ck-container">
									<CKEditor
										config={{
											toolbar: {
												items: [
													"heading",
													"|",
													"bold",
													"italic",
													"link",
													"bulletedList",
													"numberedList",
													"|",
													"alignment",
													"|",
													"indent",
													"outdent",
													"|",
													"blockQuote",
													"insertTable",
													"|",
													"undo",
													"redo",
												],
											},
											language: "fr",
										}}
										editor={ClassicEditor}
										data={publicProfile.description}
										onReady={(editor) => {
											// You can store the "editor" and use when it is needed.
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setPublicProfile({
												...publicProfile,
												description: data,
											});
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="setting-block section-dashboard">
						<div className="setting-block-description full-width">
							<h2>Médias</h2>
							<p>Personnalisez votre page en ajoutant une photo de profil et une photo de couverture.</p>
						</div>
						<div className="setting-block-content column full-width">
							<div
								onClick={() =>
									mainPicture.current
										? null
										: document.getElementById("f-input-main-picture")?.click()
								}
								className={`file-input-container margin ${!mainPicture.current ? "clickable" : ""}`}
							>
								<input
									onChange={() => get_images("f-input-main-picture", true)}
									id="f-input-main-picture"
									type="file"
									accept="image/png, image/jpeg, image/webp"
								/>
								{mainPicture.current || publicProfile.main_picture ? (
									<div className="image-container">
										<div className="image-header">
											<div></div>
											<IconButton
												onClick={() => {
													mainPicture.current = null;
													setReload((reload) => !reload);
												}}
											>
												<ClearIcon />
											</IconButton>
										</div>
										<img
											src={
												publicProfile.main_picture !== ""
													? generate_image_path(
															user.account.id,
															publicProfile.main_picture,
															"phone",
															true
													  )
													: mainPicture.current[1]
											}
										/>
									</div>
								) : (
									<span>Image de profil</span>
								)}
							</div>
							<div
								onClick={() =>
									coverPicture.current
										? null
										: document.getElementById("f-input-cover-picture")?.click()
								}
								className={`file-input-container margin ${!coverPicture.current ? "clickable" : ""}`}
							>
								<input
									onChange={() => get_images("f-input-cover-picture", false)}
									id="f-input-cover-picture"
									type="file"
									accept="image/png, image/jpeg, image/webp"
								/>
								{coverPicture.current || publicProfile.cover_picture ? (
									<div className="image-container">
										<div className="image-header">
											<div></div>
											<IconButton
												onClick={() => {
													coverPicture.current = null;
													setReload((reload) => !reload);
												}}
											>
												<ClearIcon />
											</IconButton>
										</div>
										<img
											src={
												publicProfile.cover_picture
													? generate_image_path(
															user.account.id,
															publicProfile.cover_picture,
															"phone",
															true
													  )
													: coverPicture.current[1]
											}
										/>
									</div>
								) : (
									<span>Image de couverture</span>
								)}
							</div>
						</div>
					</div>
					<div className="setting-block section-dashboard">
						<div className="setting-block-description full-width">
							<h2>Réseaux sociaux</h2>
							<p>Faites savoir à vos participants où vous retrouver sur les réseaux sociaux.</p>
						</div>
						<div className="setting-block-content column full-width">
							<TextField
								value={publicProfile.facebook_id}
								onChange={(e) =>
									setPublicProfile({
										...publicProfile,
										facebook_id: e.target.value,
									})
								}
								size="small"
								className="text-field"
								fullWidth
								label="Facebook id"
							/>
							<TextField
								value={publicProfile.twitter_id}
								onChange={(e) =>
									setPublicProfile({
										...publicProfile,
										twitter_id: e.target.value,
									})
								}
								size="small"
								className="text-field"
								fullWidth
								label="Twitter id"
							/>
							<TextField
								value={publicProfile.instagram_id}
								onChange={(e) =>
									setPublicProfile({
										...publicProfile,
										instagram_id: e.target.value,
									})
								}
								size="small"
								className="text-field"
								fullWidth
								label="Instagram id"
							/>
							<TextField
								value={publicProfile.linkedin_id}
								onChange={(e) =>
									setPublicProfile({
										...publicProfile,
										linkedin_id: e.target.value,
									})
								}
								size="small"
								className="text-field"
								fullWidth
								label="Linkedin page id"
							/>
						</div>
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default PublicProfile;
