import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.scss";
import axios from "../../../services/axios";
import main_logo from "assets/main/logo.png";
import { Segmented } from "antd";

function removeCharFromStart(str, char) {
	while (str.startsWith(char)) {
		str = str.slice(1);
	}
	return str;
}

const Digit = ({ digit }) => {
	const [currentDigit, setCurrentDigit] = useState(digit);
	const goalDigit = useRef(digit);

	const digits = "-0123456789".split("");

	const launch_digits = async (digit) => {
		let current = currentDigit;
		goalDigit.current = digit;
		while (current !== goalDigit.current) {
			await new Promise((r) => setTimeout(r, 100));
			if (digits.indexOf(current) > digits.indexOf(goalDigit.current)) {
				current = digits[digits.indexOf(current) - 1];
				setCurrentDigit(current);
			} else {
				current = digits[digits.indexOf(current) + 1];
				setCurrentDigit(current);
			}
		}
	};

	useEffect(() => {
		launch_digits(digit);
	}, [digit]);

	return (
		<div className="digit-container">
			<span>{currentDigit}</span>
		</div>
	);
};

const BigNumber = () => {
	const { key } = useParams();
	const [number, setNumber] = useState("000");
	const number_ref = useRef(0);
	const [isUp, setIsUp] = useState(null);
	const [dayLogic, setDayLogic] = useState("day");

	const get_new_number = async (dayLogic) => {
		const ret = await axios
			.get(`/api/payments/admin/${key}/${dayLogic}`)
			.then((e) => e.data)
			.catch((e) => null);

		if (ret) {
			if (number_ref.current < ret && number_ref.current !== 0) {
				setIsUp(true);
			} else if (number_ref.current > ret && number_ref.current !== 0) {
				setIsUp(false);
			}
			setNumber(ret.toString().padStart(3, "000"));
			setTimeout(() => {
				setIsUp(null);
			}, 3000);
			number_ref.current = ret;
		}
	};

	useEffect(() => {
		get_new_number(dayLogic);
		const timout = setInterval(() => {
			get_new_number(dayLogic);
		}, 15000);

		return () => {
			clearTimeout(timout);
		};
	}, [dayLogic]);

	return (
		<div className="big-number-view">
			<Segmented
				className="day-logic"
				value={dayLogic}
				options={[
					{ value: "day", label: "24 heures" },
					{ value: "week", label: "7 jours" },
					{ value: "month", label: "1 mois" },
					{ value: "all", label: "début" },
				]}
				onChange={setDayLogic}
			/>
			<div className={`big-number-container ${isUp === true ? "green" : isUp === false ? "red" : ""}`}>
				{number
					.substring(0, number.length - 2)
					.split("")
					.map((e, id) => (
						<Digit digit={e} key={id} />
					))}
				<div className="digit-container short">
					<span>,</span>
				</div>
				{number
					.substring(number.length - 2)
					.split("")
					.map((e, id) => (
						<Digit digit={e} key={id} />
					))}
			</div>
			<img src={main_logo} alt="main logo" />
		</div>
	);
};

export default BigNumber;
