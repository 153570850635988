import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { IconButton, TextField } from "@mui/material";
import { format_price } from "helpers/format";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Draggable } from "react-beautiful-dnd";
import { HolderOutlined } from "@ant-design/icons";

const TicketItem = ({ ticket, id, update }) => {
	return (
		<Draggable key={ticket.id.toString()} draggableId={ticket.id.toString()} index={id}>
			{(provided, snapshot) => (
				<div className="ticket-addon" ref={provided.innerRef} {...provided.draggableProps}>
					<div className="ticket-addon-left">
						<div className="order-container" {...provided.dragHandleProps}>
							<HolderOutlined style={{ fontSize: "18px" }} />
						</div>
						<div className="ticket-title">
							<span>{ticket.title}</span>
						</div>
					</div>
					<div className="input-fields">
						<TextField
							size="small"
							variant="outlined"
							value={ticket.amount}
							label="Quantité"
							disabled={true}
							style={{ maxWidth: 150 }}
						/>
						<TextField
							size="small"
							variant="outlined"
							value={ticket.price === 0 ? "Gratuit" : format_price(ticket.price)}
							label="Prix"
							disabled={true}
							style={{ maxWidth: 150 }}
						/>
						<IconButton onClick={update}>
							<MoreHorizIcon />
						</IconButton>
					</div>
				</div>
			)}
		</Draggable>
	);
};

export default TicketItem;
